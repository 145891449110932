.carousel {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  margin: 20px 0;
}

.carousel-image {
  flex: 0 0 auto;
  width: 100%;
  max-width: 300px;
  margin-right: 10px;
  scroll-snap-align: start;
  border-radius: 8px;
}

.carousel-image:last-child {
  margin-right: 0;
}
