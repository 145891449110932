.home-container {
  font-family: "Arial", sans-serif;
}
.mobile {
  display: none;
}
.desktop {
  display: block;
}
/* Hero Section */
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  overflow: hidden;
  color: white;
}

.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
}

.hero-overlay {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0; /* Start fully transparent */
  animation: fadeInFromBottom 3s ease-out forwards; /* Apply animation */
}

/* Define the animation */
@keyframes fadeInFromBottom {
  0% {
    transform: translate(-50%, 50%); /* Start lower than its final position */
    opacity: 0; /* Fully transparent */
  }
  100% {
    transform: translate(-50%, -50%); /* End in the original position */
    opacity: 1; /* Fully visible */
  }
}

.logo-image {
  filter: brightness(1.2) drop-shadow(0 0 5px rgba(255, 255, 255, 0.8));
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0; /* Start fully transparent */
  animation: fadeInFromTop 2s ease-out forwards; /* Apply animation */
  max-width: 800px; /* Optional: Adjust the size as needed */
  width: 100%;
  height: auto;
}

/* Define the animation */
@keyframes fadeInFromTop {
  0% {
    /* transform: translateY(-100%);  */
    /* Start off-screen at the top */
    opacity: 0; /* Fully transparent */
  }
  100% {
    /* transform: translateY(0);  */
    /* End in the original position */
    opacity: 1; /* Fully visible */
  }
}

.hero-overlay h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.hero-overlay p {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.cta-button__home {
  padding: 10px 20px;
  background-color: #ff6600;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button__home:hover {
  background-color: #ff6600d7;
}

/* Introduction Section */
.introduction-section {
  position: relative;
  padding: 20px 20px;
  background-image: url("../../../components/media/images/Backgrounds/business-people-world.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  color: #fff; /* Adjust text color for better readability */
  z-index: 1;
  background-color: #000000;
}

.introduction-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity for a darker overlay */
  z-index: -1;
  pointer-events: none;
}

.intro-content {
  position: relative;
  z-index: 2;

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.introduction-section h2 {
  color: #a95219;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Add shadow for better readability */
}

.introduction-section p {
  font-size: 1.25rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Add shadow for better readability */
}

/* Core Values Section */
.core-values-section {
  padding: 50px 20px;
  background-color: #e9ecef;
  text-align: center;
}

.core-values-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.values-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.value-item {
  background-color: #000000;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.value-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.value-item p {
  font-size: 1rem;
  line-height: 1.5;
}
.arrow-down {
  margin-top: 20px;
}

.arrow-down svg {
  animation: bounce 2s infinite;
  cursor: pointer;
}

/* Bouncing animation */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(5px);
  }
}
/* How We Work Section */
.how-we-work-section {
  padding: 100px 20px;
  background-color: white; /* Adjust background as needed */
  text-align: center;
}

.how-we-work-content {
  max-width: 800px;
  margin: 0 auto;
  color: #000000; /* Adjust text color as needed */
}

.how-we-work-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.how-we-work-section p {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.how-we-work-section .cta-button__home {
  padding: 10px 20px;
  background-color: #ff6600; /* Adjust button color as needed */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.how-we-work-section .cta-button__home:hover {
  background-color: #ff6600d7; /* Darker shade on hover */
}
@media (max-height: 860px) {
  .hero-overlay {
    top: 80%;
  }
}

@media (max-width: 700px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .values-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  .hero-section {
    height: 100vh;
    min-height: 600px;
    /* background-image: url("../../../components/media/video//mobile_zoom.gif"); */
    background-image: url("../../../components/media/images/Backgrounds/MobileTwo.png");
    background-size: cover;
    background-position: 50% center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
  }

  .hero-video {
  }
  .logo-image {
    filter: brightness(1.9) drop-shadow(0 0 10px rgba(255, 255, 255, 2));
    width: 100%;
    align-self: center;
    top: 10%;
    left: 50%;
    opacity: 0; /* Start fully transparent */
    animation: fadeInFromTop 2s ease-out forwards; /* Apply animation */
  }

  /* Define the fade-in animation */
  @keyframes fadeIn {
    0% {
      opacity: 0; /* Fully transparent at the start */
    }
    100% {
      opacity: 1; /* Fully visible at the end */
    }
  }

  .hero-overlay h1 {
    font-size: 1.5rem;
    margin-bottom: 0px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }

  .hero-overlay {
    top: 30%;
  }

  .cta-button__home {
    padding: 10px 20px;
    background-color: #ff6600;
    border-radius: 5px;
    font-size: 1rem !important;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 70vw;
  }
}
