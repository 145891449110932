.just-video {
  text-align: center;
  margin: 20px 0;
}

.just-video video {
  width: 80%;
  max-width: 600px;
  border-radius: 8px;
}
