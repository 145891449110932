.right-left {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.right-left-text {
  width: 50%;
  margin-right: 20px;
}

.right-left-text p {
  font-size: 1.2em;
  line-height: 1.6;
}

.right-left-image {
  width: 50%;
  border-radius: 8px;
}

.right-left-text video {
  width: 100%;
  border-radius: 8px;
}
