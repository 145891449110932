.footer__container {
  /* width: 100%; */
  height: 100%;
  min-height: 75px;
  background-color: black;
  color: white;
  padding: 5%;
  display: flex;
  justify-content: space-around;
}
.nav-link {
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.nav-link:hover {
  text-decoration: underline;
  color: #ff6600;
}
.logo-cutout-footer {
  width: 200px;
  filter: brightness(2.2) drop-shadow(0 0 5px rgba(255, 255, 255, 0.9));
  margin: 0 auto;
  display: block;
}
