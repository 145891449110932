.all-blogs__container {
  min-height: 100%;
  /* padding-bottom: %; */
}

/* Banner styling */
.blogs-banner {
  position: relative;
  background-image: url("https://res.cloudinary.com/bobalobbadingdong/image/upload/v1728171049/Mark%20Andersen/Investments_AdobeStock_460475199_xjeh0y.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  padding: 50px 0;
  text-align: center;
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 40px;
  border-bottom: 4px solid #005bb5;
  z-index: 1;
  padding-top: 10%;
}

/* Dark overlay for the banner */
.blogs-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay with 50% opacity */
  z-index: -1; /* Ensure the overlay is behind the text */
}

/* Styling for the loading text */
.loading {
  font-size: 1.5em;
  text-align: center;
  margin: 50px 0;
  color: #0073e6;
}

/* Container for the blog list */

/* Individual blog item */
.blog-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 300px;
  margin: 20px;
  padding: 10px;
  transition: transform 0.3s ease;
}

.blog-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-item img {
  width: 100%;
  border-radius: 8px 8px 0 0;
}

/* Centering the blog title */
.blog-item h2 {
  font-size: 1.5em;
  margin: 15px 0 10px;
  color: #333;
  text-align: center; /* Blog title centered */
}

/* Blog preview text left-aligned */
.blog-preview {
  font-size: 0.9em;
  color: #555;
  margin-top: 5px;
  text-align: left; /* Ensure preview text is left-aligned */
}

/* Styling for "Read More" link */
.read-more {
  font-size: 0.9em;
  color: white;
  text-align: left; /* Align "Read More" to the left */
  margin-top: 10px;
  display: block;
}

.read-more:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Styling for the blog item links */
.blog-item a {
  text-decoration: none;
  color: inherit;
}

.blog-item a:hover h2 {
  color: #e7ab42; /* Change title color on hover */
}
/* From Uiverse.io by KSAplay */
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.loading-text {
  color: black;
  font-size: 14pt;
  font-weight: 600;
  margin-left: 10px;
}

.dot {
  margin-left: 3px;
  animation: blink 1.5s infinite;
}
.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

.loading-bar-background {
  --height: 30px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
  width: 200px;
  height: var(--height);
  background-color: #212121 /*change this*/;
  box-shadow: #0c0c0c -2px 2px 4px 0px inset;
  border-radius: calc(var(--height) / 2);
}

.loading-bar {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  --height: 20px;
  width: 0%;
  height: var(--height);
  overflow: hidden;
  background: rgb(222, 74, 15);
  background: linear-gradient(0deg, rgba(222, 74, 15, 1) 0%, rgba(249, 199, 79, 1) 100%);
  border-radius: calc(var(--height) / 2);
  animation: loading 4s ease-out infinite;
}

.white-bars-container {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 18px;
}

.white-bar {
  background: rgb(255, 255, 255);
  background: linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 70%);
  width: 10px;
  height: 45px;
  opacity: 0.3;
  rotate: 45deg;
}

@keyframes loading {
  0% {
    width: 0;
  }
  80% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
/* Card styling from Uiverse.io by Sashank02 */
.card {
  padding: 20px;
  width: 330px;
  min-height: 400px;
  /* height: 100%; */
  border-radius: 20px;
  /* background: #e8e8e8; */
  background: #2e54a7;
  box-shadow: 5px 5px 6px #dadada, -5px -5px 6px #f6f6f6;
  transition: 0.4s;
  margin: 20px;
  /* Needed for absolute positioning */
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: white;
}

.card:hover {
  transform: translateY(-10px);
}

.card-title {
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin: 0px 0 0 0px;
}

.card-image {
  min-height: 170px;
  background-color: #c9c9c9;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  box-shadow: inset 8px 8px 10px #c3c3c3, inset -8px -8px 10px #cfcfcf;
}

.card-body {
  margin: 13px 0 0 10px;
  color: rgb(31, 31, 31);
  font-size: 15px;
  color: white;
}

.footer {
  /* position: absolute;
  bottom: 20px;
  left: 10px;
  font-size: 13px;
  color: #636363; */
}

.read-more {
  font-weight: 700;
  color: white;
  text-decoration: none;
}

.read-more:hover {
  text-decoration: underline;
}

/* Ensure the entire card is clickable */
.card-link {
  text-decoration: none;
  color: inherit; /* Ensure that no styles are inherited when wrapping with Link */
}
.blogs-holder {
  height: 100%;
  display: flex;
}
.blogs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  height: 100%;
}

.blogs-banner {
  text-align: center;
  padding: 30px;
  background-color: #2e54a7;
  color: white;
  /* padding-top: 8px; */
}

.loading {
  text-align: center;
  margin-top: 50px;
}
.card__image {
  width: 100%;
}
