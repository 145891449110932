.winery-lp {
  font-family: "Montserrat", sans-serif;
  color: #2d2d2d;
}
.only-mobile {
  display: none;
}

/* Hero Section */
/* Hero Section */
.winery-hero-section {
  position: relative;
  height: auto; /* Allow the section to adjust based on content */
  overflow: hidden;
  padding: 50px 20px; /* Match the padding of the overlay */
}

.winery-hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0; /* Ensure the video stays behind the overlay */
}

.winery-hero-overlay {
  position: relative;
  z-index: 2; /* Place overlay above the video */
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5); /* Optional dark overlay for better readability */
  padding: 20px; /* Reduce padding for a tighter fit */
  /* max-width: 800px;  */
  margin: 0 auto; /* Center the overlay content */
}

.winery-hero-overlay h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.winery-hero-overlay p {
  font-size: 1rem;
}

/* Section Headings */
h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #8b0000;
  text-align: center;
}

/* Advantage Section */

.advantage-section {
  background-color: #8b0000;
  color: white !important;
  h2 {
    color: white !important;
  }
}

.advantage-section,
.integrations-section,
.services-section,
.success-section,
.cta-section {
  padding: 50px 20px;
  text-align: center;
}

/* Integrations Section */
.integrations-section ul,
.success-section ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  max-width: 800px;
  margin: 0 auto;
}

/* Services Cards */
.services-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-card {
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 20px;
  width: 280px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #8b0000;
}

/* CTA Section */
.cta-section {
  background-color: #8b0000;
  color: #fff;
  padding: 50px 20px;
}

.cta-button {
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: #ff6600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #e65c00;
}
@media (max-width: 850px) {
  .only-mobile {
    display: inline;
  }
}
