/* Services Section */
.services-section {
  text-align: center;
  padding: 50px 20px;
  background-color: black;
}

.services-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: white; /* Adjust to a redwood theme if needed */
}

.services-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the cards */
  gap: 20px;
}

.service-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 300px; /* Consistent card width */
  min-height: 350px; /* Ensures all cards have the same height */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space between content and button */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  color: #333;
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #8b0000; /* Dark redwood color for titles */
}

.service-card p {
  font-size: 1rem;
  line-height: 1.5;
  flex-grow: 1; /* Allows the content to grow and push the button down */
  margin-bottom: 15px;
}

.service-card ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px; /* Adds space between the list and button */
  flex-grow: 1; /* Allows the content to grow and push the button down */
}

.service-card li {
  margin-bottom: 5px;
}

.cta-button {
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #ffffff;
  background-color: #ff6600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e65c00;
}
