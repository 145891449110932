/* General Blog Layout */
h1 {
  text-align: center;
  margin: 20px 0;
  font-size: 2.5em;
}

img,
video {
  /* max-width: 100%;
  border-radius: 8px;
  margin: 20px 0; */
}

/* SimpleText */
.simple-text p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* LeftRightImageText and RightLeftImageText */
.left-right,
.right-left {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.left-right img,
.right-left img {
  width: 50%;
  border-radius: 8px;
}

.left-right-text,
.right-left-text {
  width: 50%;
  padding: 20px;
}

.left-right-text p,
.right-left-text p {
  font-size: 1.2em;
  line-height: 1.6;
}

.left-right-video,
.right-left-video {
  width: 100%;
  border-radius: 8px;
}

/* Carousel */
.carousel {
  display: flex;
  overflow-x: auto;
}

.carousel-image {
  width: 100%;
  max-width: 300px;
  margin-right: 10px;
  border-radius: 8px;
}

/* JustImage and JustVideo */
.just-image img,
.just-video video {
  display: block;
  margin: 0 auto;
}
/* Banner for main image and title */
.blog-banner {
  position: relative;
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  margin-bottom: 40px;
  padding-top: 10%;
}
/* Subtitle styling under the blog title */
.blog-subtitle {
  font-size: 1.4em;
  margin-top: 10px;
  color: #ddd;
  font-weight: 300;
}

/* Dark overlay for the banner */
.blog-banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Darker overlay with 50% opacity */
  z-index: 1;
}

/* Main image as background */
.blog-banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the whole section */
  z-index: 0;
}

/* Centered content (title and preview) */
.blog-banner-content {
  position: relative;
  z-index: 2;
  color: white;
  max-width: 800px;
  text-align: center;
}

.blog-title {
  font-size: 3em;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.blog-content-preview {
  font-size: 1.2em;
  margin-top: 10px;
}

/* Blog sections container */
.blog-sections-container {
  max-width: 1000px; /* Limits the width of the content to 1000px */
  margin: 0 auto; /* Centers the container horizontally */
  padding: 20px; /* Adds padding around the content */
}

/* Example for left and right alignment containers */
.left-right,
.right-left {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

/* Add padding and max-width to individual components, if needed */
.simple-text,
.left-right,
.right-left,
.carousel,
.just-image,
.just-video {
  max-width: 100%;
  margin: 20px auto;
}
/* Styling for Markdown text in the left-right or right-left section */
.left-right-text p,
.right-left-text p {
  margin-bottom: 1em;
  line-height: 1.6;
}

.left-right-text h3,
.right-left-text h3 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.left-right-text ul,
.right-left-text ul {
  list-style-type: disc;
  padding-left: 20px;
}
/* Footer Section Styling */
.blog-footer {
  max-width: 800px; /* Limits the width of the content */
  margin: 40px auto; /* Centers the footer horizontally */
  padding: 20px; /* Adds padding around the content */
  text-align: left; /* Ensures text inside the footer is left-aligned */
}

.blog-footer p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 1.2em;
}
