/* Root variables for consistent theming */
:root {
  --primary-color: #1d4ed8; /* Blue */
  --primary-hover-color: #1e40af; /* Darker Blue */
  --secondary-color: #ef4444; /* Red */
  --secondary-hover-color: #b91c1c; /* Darker Red */
  --background-color: #f3f4f6; /* Light Gray */
  --form-background: #ffffff; /* White */
  --text-color: #1f2937; /* Dark Text */
  --border-color: #d1d5db; /* Light Gray Border */
  --focus-color: #6366f1; /* Indigo */
  --shadow-color: rgba(0, 0, 0, 0.1);
  --font-family: "Arial", sans-serif;
}
.emailjs-container-closed {
  display: none;
}
/* General container styles */
.emailjs-container-opened {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed; /* Ensures it's fixed to the viewport */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for element's own size */
  background-color: var(--background-color);
  font-family: var(--font-family);
  color: var(--text-color);
  z-index: 1000; /* Ensures it stays on top of other elements */
  box-shadow: 0 4px 6px var(--shadow-color);
  border-radius: 8px; /* Optional: Rounded corners for aesthetics */
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Header */
.emailjs-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--primary-color);
}

/* Form wrapper */
.form-wrapper {
  background-color: var(--form-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px var(--shadow-color);
}

/* Form labels */
form label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

/* Input fields */
.form-input,
.form-textarea {
  width: 95%;
  padding: 10px;
  font-size: 14px;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 16px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 9999;
}

.form-input:focus,
.form-textarea:focus {
  border-color: var(--focus-color);
  box-shadow: 0 0 0 2px var(--focus-color) 50;
}

/* Textarea */
.form-textarea {
  resize: none;
  height: 100px;
}

/* Submit button */
.form-submit-button {
  background-color: var(--primary-color);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-submit-button:hover {
  background-color: var(--primary-hover-color);
}

/* Exit button */
.form-exit-button {
  background-color: var(--secondary-color);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.form-exit-button:hover {
  background-color: var(--secondary-hover-color);
}

/* Responsive design */
@media (max-width: 768px) {
  .form-wrapper {
    width: 90%;
  }

  .emailjs-header {
    font-size: 20px;
  }

  .form-input,
  .form-textarea {
    font-size: 12px;
  }

  .form-submit-button,
  .form-exit-button {
    font-size: 12px;
  }
}
