.about-container {
  font-family: "Arial", sans-serif;
  /* padding: 20px; */
}

/* General Section Styling */
section {
  /* margin-bottom: 50px; */
}

/* Redwood Story Section */
.redwood-story-section {
  text-align: center;
  background-color: #000000;
  padding: 40px 20px;
  /* border-radius: 10px; */
  color: white;
}

.redwood-story-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.redwood-story-section p {
  font-size: 1.25rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

/* Combined Mission and Values Section */
.our-mission-section {
  background-image: url("../../components/media/images/Backgrounds/Digital\ Advertising\ AdobeStock_298627676\ copy.jpeg");
  position: relative; /* Ensure the parent element is positioned */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #32a6ff;
  overflow: hidden; /* Ensures the pseudo-element covers the section */
  padding: 50px 20px;
  padding-bottom: 100px;
}

.our-mission-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 0; /* Ensure this is above the background but below text */
  pointer-events: none; /* Ensure overlay does not block interactions */
}

.mission-content,
.values-cards {
  position: relative; /* Layer above the overlay */
  z-index: 2;
}

.our-mission-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #32a6ff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Shadow for better readability */
}

.our-mission-section h3 {
  font-size: 1.25rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto 40px; /* Add space below */
  color: #32a6ff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Shadow for readability */
}
/* Values Cards Styling */
.values-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the cards */
  gap: 20px; /* Add space between cards */
}

.notification {
  display: flex;
  flex-direction: column;
  isolation: isolate;
  position: relative;
  width: 280px; /* Adjust width as needed */
  height: auto;
  background: #29292c;
  border-radius: 1rem;
  overflow: hidden;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  --gradient: linear-gradient(to bottom, #2eadff, #3d83ff, #7e61ff);
  --color: #32a6ff;
}

.notification:before {
  position: absolute;
  content: "";
  inset: 0.0625rem;
  border-radius: 0.9375rem;
  background: #18181b;
  z-index: 2;
}

.notification:after {
  position: absolute;
  content: "";
  width: 0.25rem;
  inset: 0.65rem auto 0.65rem 0.5rem;
  border-radius: 0.125rem;
  background: var(--gradient);
  transition: transform 300ms ease;
  z-index: 4;
}

.notification:hover:after {
  transform: translateX(0.15rem);
}

.notititle {
  color: var(--color);
  padding: 0.65rem 0.25rem 0.4rem 1.25rem;
  font-weight: 500;
  font-size: 1.1rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notititle {
  transform: translateX(0.15rem);
}

.notibody {
  color: #99999d;
  padding: 0 1.25rem 1rem 1.25rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notibody {
  transform: translateX(0.25rem);
}

.notiglow,
.notiborderglow {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle closest-side at center, white, transparent);
  opacity: 0;
  transition: opacity 300ms ease;
}

.notiglow {
  z-index: 3;
}

.notiborderglow {
  z-index: 1;
}

.notification:hover .notiglow,
.notification:hover .notiborderglow {
  opacity: 0.1;
}

/* Team Section */
.team-section {
  text-align: center;
  padding: 30px 20px;
  background-color: #000000;
  color: #ffffff;
}

.team-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.our-values-title {
  color: #32a6ff !important;
}

.card-holder {
  display: flex;
  justify-content: center; /* Center the cards */
  flex-wrap: wrap;
  gap: 20px; /* Space between cards */
}

.card {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  width: 30%; /* Ensure cards take up 30% of the screen width */
  max-width: 500px; /* Maintain a max-width for larger screens */
  min-width: 250px; /* Ensure a minimum width for smaller screens */
  /* min-height: 500px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left; /* Center-align for uniformity */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
}

.headshot-img {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Ensure images are uniformly sized */
  border-radius: 50%; /* Make images circular */
  margin-bottom: 15px;
  object-fit: cover; /* Ensure the image fills the area without distortion */
}

.card-content {
  width: 90%; /* Ensure consistent padding inside the card */
}

.card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.headshot-img {
  width: 150px;
  height: 150px;
  border-radius: 5%;
  margin-bottom: 15px;
}

.card-content h3 {
  margin: 0 0 10px;
}

.card-content p {
  font-size: 14px;
  line-height: 1.5;
}

/* How We Work Section */
.how-we-work-section {
  text-align: center;
  padding: 30px 20px;
}

.how-we-work-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.how-we-work__text {
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  font-size: 1.25rem;
}

.cta-button {
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #ffffff;
  background-color: #ff6600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e65c00;
}

@media (max-width: 800px) {
  .team-section {
    text-align: center;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
  }
  .card {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    width: 80%; /* Ensure cards take up 30% of the screen width */
    max-width: 500px; /* Maintain a max-width for larger screens */
    min-width: 250px; /* Ensure a minimum width for smaller screens */
    /* min-height: 500px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left; /* Center-align for uniformity */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
