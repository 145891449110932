.calendly-modal-closed {
  display: none;
}
.calendly-modal-opened {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed; /* Pin the modal to the viewport */
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  z-index: 9999;
}
.modal__x {
  position: relative;
  top: 20px;
}
.opened-modal__holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 800px) {
  .opened-modal__holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    top: 5%;
  }
}
