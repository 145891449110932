/* blog.css */

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

.blog-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: #fff;
  padding: 8px 15px;
  z-index: 100;
  border-radius: 4px;
}

.skip-link:focus {
  top: 10px;
}

.blog-header {
  text-align: center;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.blog-header h1 {
  font-size: 2rem;
  color: #222;
}

.blog-header p {
  color: #777;
  font-size: 0.9rem;
}

.blog-cover {
  width: 100%;
  border-radius: 8px;
  margin-top: 15px;
}

.blog-content {
  padding: 10px 0;
}

.blog-content h2 {
  color: #444;
  font-size: 1.5rem;
  margin: 20px 0 10px;
}

.blog-content p {
  margin: 10px 0;
  color: #555;
}

.blog-content strong {
  color: #000;
}

.blog-content ul,
.blog-content ol {
  margin: 15px 0;
  padding-left: 20px;
  color: #555;
}

.blog-content li {
  margin: 5px 0;
}

.blog-footer {
  margin-top: 20px;
  border-top: 2px solid #eee;
  text-align: center;
  padding-top: 10px;
  color: #666;
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-container {
    padding: 15px;
  }

  .blog-header h1 {
    font-size: 1.8rem;
  }

  .blog-content h2 {
    font-size: 1.3rem;
  }
}
