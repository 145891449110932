/* CaseStudy.css */

.case-study-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 15px;
  font-family: "Arial", sans-serif;
  color: #333;
}

.case-study-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #2c3e50;
}

.case-study-subtitle {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: #34495e;
}

.company-name {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #e74c3c;
}

.roas {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 30px;
  color: #27ae60;
}

.roas-number {
  font-weight: bold;
}

.case-study-section {
  margin-bottom: 25px;
}

.section-header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2980b9;
}

.section-content {
  font-size: 1rem;
  margin-left: 15px;
}

.section-list {
  list-style-type: disc;
  margin-left: 40px;
  font-size: 1rem;
  color: #555;
}

.section-paragraph {
  font-size: 1rem;
  margin-bottom: 15px;
  line-height: 1.6;
}
.full-width-img {
  width: 100%;
}
@media screen and (max-width: 600px) {
  .case-study-container {
    padding: 15px 10px;
  }

  .case-study-title {
    font-size: 2rem;
  }

  .case-study-subtitle {
    font-size: 1.25rem;
  }

  .company-name {
    font-size: 1.75rem;
  }

  .roas {
    font-size: 1.5rem;
  }

  .section-header {
    font-size: 1.15rem;
  }

  .section-content,
  .section-list,
  .section-paragraph {
    font-size: 0.95rem;
  }
}
